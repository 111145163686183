@import '../../../../includes/assets/css/consts.scss';
@import '../base/mixins';

.dpit-theme-minimal {

	// buttons
	//--dpit-btn-tx-alpha: 0.8;
	//--dpit-btn-tx-alpha-2: 0.85;
	--dpit-btn-tx-color: var(--accent-contrast-inv-color);
	--dpit-btn-on-tx-color: var(--accent-color);
	--dpit-btn-bg-color: transparent;
	--dpit-btn-on-bg-color: transparent;

	--dpit-btn-tx-alpha: 0.8;
	--dpit-btn-tx-alpha-2: 0.95;

	//--dpit-btn-on-bg-alpha: 1;

	// scrollbar
	--dpit-scrollbar-bg-color: var(--accent-color-gentle);

	// number
	--dpit-number-bg-color: var(--accent-color-gentle);


	// leave default for mobiles
	@include atHoverAbleDev {
		.dpit-scrollbar {
			&::-webkit-scrollbar {
				height: unit(10);
				width: unit(10);
			}
		}
	}

	.dpit-button {
		padding: unit(5) unit(23);
		font-size: unit(38);


		&--no-text:not(.dpit-button--done) {
			font-size: unit(50);

			.dpit-button__ico-wrap {
				&--symbol {
					font-size: unit(50);
				}
			}

			&.dpit-button--skip {
				padding-left: unit(0);
				padding-right: unit(10);
			}
		}




		&--next {
			padding-right: unit(3);

			&:active {
				transform: translateX(unit(3));
			}
		}

		&--prev {
			padding-left: unit(3);

			&:active {
				transform: translateX(unitNeg(3));
			}
		}



		&--skip {
			padding-left: unit(3);
			padding-right: unit(10);

			&:active {
				transform: scale(0.99);
			}
		}
	}


	.dpit-number {
		&__text {
			font-weight: 700;
		}
	}

	.dpit-main-controls {
		padding-top: unit(10);
	}

	.dpit-bullets {
		ul {
			&.dpit-has-scrollbar {
				margin-bottom: unit(10);
			}
		}
	}

	.dpit-progress {
		height: unit(12);
	}

	.dpit-checkbox-label {
		margin-top: 0;

		@include atTouchDev {
			margin-top: unit(20);
		}


	}
}